function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        //Mobile nav
        $('.open-mobile-menu').on('click', function () {
            let mobilenav = $('.mobilenav');
            if(mobilenav.hasClass('hidden')){
                mobilenav.addClass('active');
                mobilenav.removeClass('hidden');
            } else {
                mobilenav.removeClass('active');
                mobilenav.addClass('hidden');
            }
        });

        $('.close-mobile-nav').on('click', function () {
            if(mobilenav.hasClass('active')){
                mobilenav.addClass('hidden');
                mobilenav.removeClass('active');
            }
        });

        if ($('.menu-item-has-children')) {
            $('.menu-item-has-children > :first-child').append('<i class="submenu-arrow"></i>');
        }

        $('.submenu-arrow').on('click', function (e) {
            e.preventDefault();
            if($(this).closest('.submenu-arrow').hasClass('submenu-arrow-down')){
                $(this).closest('.submenu-arrow').removeClass('submenu-arrow-down');
                $(this).closest('li').children('.sub-menu').toggleClass('is-open');
            } else{
                $(this).closest('.submenu-arrow').addClass('submenu-arrow-down');
                $(this).closest('li').children('.sub-menu').addClass('is-open');

            }
        });
    });
})(jQuery);
